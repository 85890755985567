.identity-main {
  background-size: cover;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  font-family: Arial, sans-serif;
  position: fixed;
  width: 100%;
  top: 50px;
}

.onload-bg-img {
  background: url('../../assets/bg.jpg') no-repeat center center;
  background-size: cover;
}

.verify-complete-bg-img {
  background: url('../../assets/VerifyComplete.jpg') no-repeat center center;
  background-size: cover;
}

.enforced-enrollment-bg-img {
  background: url('../../assets/enforcedEnrollment.png') no-repeat center center;
  background-size: cover;
}

.action-required-message {
  font-size: 22px;
  background-color: #e20074;
  color: #ffffff;
  border-radius: 5px;
  padding: 16px;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  align-items: center;
  justify-content: center;

  // justify-content: start;
  // padding-top: 200px;
}

.container,
.container * {
  box-sizing: border-box;
}

.white-box {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.668);
  // text-align: center;
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
}

.title-box {
  background-color: rgba(43, 43, 43, 0.93);
  border-radius: 10px;
  padding: 6px 16px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.668);
  text-align: center;
  margin-bottom: 30px;
}

.divider {
  width: 400px;
  height: 2px;
  background-color: #d6d6d6;
  border-radius: 10px;
}

.verify-button {
  background-color: rgb(4, 26, 85);
  color: #ffffff;
  cursor: pointer;
  border: none;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  align-items: center;
  border-radius: 32px;
  width: 360px;
  height: 56px;
}

.verify-button img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.outcome-box-greyed {
  background-color: #717171;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  width: 360px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin: 0 auto;
}

.outcome-box-success {
  background-color: #02ae02;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  width: 360px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.outcome-box-exit {
  margin: 0 auto;
  background-color: #870000;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 32px;
  width: 360px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outcome-box-exit img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.learn-more-pwd-less,
.survey {
  display: block;
  margin: 5px 0;
}

.survey {
  padding: 10px 10px;
  display: inline-block;
  color: white;
  background-color: #e20074;
  text-decoration: none;
  border-radius: 5px;
  // font-weight: bold;
  transition: background-color 0.3s ease;
}

.survey:hover {
  background-color: #d0006e;
}

.error-message {
  font-weight: bold;
  font-size: 12px;
  color: red;
}
.loader-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.link-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

.error-message-container {
  width: 350px;
  margin: 0 auto;
  word-wrap: break-word;
  white-space: normal;
}

.error-message {
  font-size: 14px;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

// @media screen and (min-width: '768px') and (max-width: '1280px') {
//   .onload-bg-img {
//     background: url('../../assets/Ipad.jpg') no-repeat center top;
//     background-size: cover;
//   }

//   .verify-complete-bg-img {
//     background: url('../../assets/Ipad.jpg') no-repeat center top;
//     background-size: cover;
//   }
// }

@media screen and (max-width: '1023px') {
  .container {
    row-gap: 32px;
  }
}

@media screen and (max-width: '767px') {
  .container {
    row-gap: 24px;
  }

  .white-box {
    width: calc(100% - 48px);
  }
  .outcome-box-success,
  .outcome-box-greyed,
  .outcome-box-exit,
  .verify-button {
    width: 280px;
  }
  .action-required-message {
    width: calc(100% - 48px);
  }
  // .onload-bg-img {
  //   background: url('../../assets/Mobile.jpg') no-repeat center top;
  //   background-size: cover;
  // }

  // .verify-complete-bg-img {
  //   background: url('../../assets/Mobile.jpg') no-repeat center top;
  //   background-size: cover;
  // }
}
.radio-btn {
  & .MuiSvgIcon-root {
    font-size: 24;
  }
  &.Mui-checked {
    color: #e20074 !important;
  }
}

.submitButton {
  background-color: #ffffff !important;
  border-radius: 3px !important;
  color: #e20074 !important;
  border: none !important;
  padding: 0.8rem 2rem !important;
  width: 100% !important;
  margin-top: 2rem !important;
  cursor: pointer !important;
  font-size: 1rem !important;
}


.loader-overlay {
  width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

