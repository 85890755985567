@use '../common/style.module.scss' as styles;

$tmo_magenta: #e20074;
$tmo_gray: #858585;
$white_themes: #ffffff;
$black_themes: #000000;

.instructions-container {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  max-height: calc(100vh - 100px);
  overflow: auto;

  /* Custom scrollbar styles */
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgray transparent;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: gray;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.sticky-passcode {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffffff;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.download-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  button {
    font-size: 1rem;
    background-color: #ffffff !important;
    color: #e20074 !important;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 4px;
    text-transform: none;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
}

.submitButton {
  box-sizing: border-box;
  padding: 0 36px;
  font-style: normal;
  color: $white_themes;
  line-height: 16px;
  background: $tmo_magenta;
  border: 1px solid $tmo_magenta;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;
  text-transform: none;
}

.commonButton {
  text-transform: none !important;
  font-weight: bold !important;
  background-color: #ffffff !important;
  color: #e20074 !important;
  border: 2px solid #e20074 !important;
  padding: 8px 24px !important;
  border-radius: 4px !important;

  &:hover {
    background-color: #e20074 !important;
    color: #ffffff !important;
  }
}
