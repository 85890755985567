@use '../../common/style.module.scss' as styles;

/* Default background var for footer */
:root {
  --commonfooter-background: url('../../../assets/Icons_Illustrations/Welcome_Pre_Signin/rectangle_login.svg');
}

.unauthFooter {
  --commonfooter-background: none; /* Override background var when used in error components */
}

.container {
  background-image: url('../../../assets/Icons_Illustrations/error_bg.svg');
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

  .errorHeading {
    font-family: TeleGroteskNextRegular, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 100.61%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .errorText {
    position: relative;
    margin: 4rem 0;
    font-family: TeleGroteskNextRegular, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #ffffff;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 45%;
    @media screen and (max-width: styles.$tabletSize) {
      max-width: 70%;
    }
  }

  .primaryButton {
    color: styles.$tmo_magenta;
    border: 1px solid styles.$tmo_magenta;
    border-radius: 5px;
    height: 3rem;
    width: 25rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 700;
    cursor: pointer;
  }
  .secondaryButton {
    color: white;
    font-size: 1.125rem;
    font-weight: 500;
    text-decoration: underline;
    line-height: 18px;
    word-wrap: break-word;
    margin: 1rem;
    cursor: pointer;
  }

.unauthFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.unauthFooter .loginFooter {
  width: 100%;
  background: transparent;
}

